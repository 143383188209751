<template>
  <main id="content" role="main">
    <!-- 首页搜索 -->
    <section
      class="bg-img-hero-main bg-dark"
      style="background-image: url(/images/svg/components/bg-shape5e1f.svg)"
    >
      <div class="container space-top-2 space-bottom-3">
        <div class="row justify-content-between align-items-center mb-4">
          <div class="col-md-12 col-lg-7">
            <div class="pr-lg-4 pr-xll-5">
              <h1 class="h4 text-white mb-3">
                {{ getName("Home", "SearchTitle") }}
              </h1>
              <!-- 搜索框 -->
              <div class="input-group input-group-shadow">
                <input
                  id="txtSearchInput"
                  type="text"
                  :placeholder="searchPlaceholder"
                  class="form-control searchautocomplete"
                  aria-describedby="button-header-search"
                  autocomplete="off"
                  v-on:keyup.enter="_search()"
                  v-model="searchWords"
                  name="q"
                />
                <input type="hidden" value="" id="hdnSearchText" />
                <input id="hdnIsTestNet" value="False" type="hidden" />
                <div class="input-group-append">
                  <button
                    v-loading="searchLoading"
                    class="btn btn-primary"
                    type="submit"
                    @click="_search()"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <p class="text-white-70 mb-0" style="min-height: 22px"></p>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="d-none d-lg-block text-center pl-lg-4 pl-xll-5"></div>
          </div>
        </div>
      </div>
    </section>
    <div class="container space-bottom-1 mt-n5">
      <div id="ContentPlaceHolder1_mainboxes" class="card mb-4">
        <div class="card-body p-4">
          <div class="row mx-gutters-md-1 align-items-center">
            <div class="col-md-6 col-lg-4">
              <div class="media align-items-center">
                <figure class="mr-2">
                  <img
                    class="u-sm-avatar"
                    src="images/home/rch_price.png"
                    alt=""
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "Price") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    href="#"
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="View Historical RCH Price"
                    >${{ formatNumber(price, 6) }}
                  </a>
                </div>
              </div>
              <hr class="hr-space-lg" />
              <div class="media align-items-center">
                <figure class="u-sm-avatar mr-2">
                  <img
                    src="images/home/network hashrate.png"
                    alt=""
                    class="u-sm-avatar"
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "NETWORK HASHRATE") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    href="#"
                    rel="tooltip"
                    data-placement="bottom"
                    title=""
                  >
                    {{ hashrate }}
                    <!-- <span class='text-secondary'></span> -->
                  </a>
                </div>
              </div>
              <hr class="d-block d-md-none hr-space-lg" />
            </div>
            <div
              class="
                col-md-6 col-lg-4
                u-ver-divider u-ver-divider--left u-ver-divider--none-md
              "
            >
              <div class="media align-items-center">
                <figure class="u-sm-avatar mr-2">
                  <img
                    src="images/home/latest block.png"
                    alt=""
                    class="u-sm-avatar"
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "Latest Blocks") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    href="#"
                    rel="tooltip"
                    data-placement="bottom"
                    title=""
                    ><span id="lastblock">{{ blockHeight }}</span></a
                  >
                </div>
              </div>
              <hr class="hr-space-lg" />
              <div class="media align-items-center">
                <figure class="u-sm-avatar mr-2">
                  <img
                    src="images/home/accounts.png"
                    alt=""
                    class="u-sm-avatar"
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "ACCOUNTS") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    data-toggle="tooltip"
                    data-placement="left"
                    data-title="View Difficulty Growth Chart"
                    href=""
                  >
                    <a
                      class="text-size-1 text-link"
                      href="#"
                      rel="tooltip"
                      @click.prevent="showAccounts()"
                      data-placement="bottom"
                      title=""
                      >{{ accountCounts }}
                    </a></a
                  >
                </div>
              </div>
              <hr class="d-block d-md-none hr-space-lg d-m-none" />
            </div>

            <div
              class="
                col-md-6 col-lg-4
                u-ver-divider u-ver-divider--left u-ver-divider--none-md
              "
            >
              <hr class="d-none hr-space-lg d-lg-none d-md-block" />
              <div class="media align-items-center">
                <figure class="u-sm-avatar mr-2">
                  <img
                    src="images/home/block time.png"
                    alt=""
                    class="u-sm-avatar"
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "BLOCK TIME") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    href="#"
                    rel="tooltip"
                    data-placement="bottom"
                    title="The latest Block No"
                    ><span id="lastblock">{{ blocktime }}</span></a
                  >
                </div>
              </div>
              <hr class="hr-space-lg" />
              <div class="media align-items-center">
                <figure class="u-sm-avatar mr-2">
                  <img
                    src="images/home/difficulty.png"
                    class="u-sm-avatar"
                    alt=""
                  />
                </figure>
                <div class="media-body">
                  <h2 class="font-size-1 text-uppercase text-secondary mb-0">
                    {{ getName("Home", "DIFFICULTY") }}
                  </h2>
                  <a
                    class="text-size-1 text-link"
                    data-toggle="tooltip"
                    data-placement="left"
                    data-title="View Difficulty Growth Chart"
                    href="#"
                  >
                    <a
                      class="text-size-1 text-link"
                      href="#"
                      rel="tooltip"
                      data-placement="bottom"
                      title="Total DIFFICULTY"
                      >{{ difficulty }}</a
                    ></a
                  >
                </div>
              </div>
              <hr class="d-block d-md-none hr-space-lg" />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="card h-100">
            <div class="card-header">
              <h2 class="card-header-title">
                {{ getName("Home", "Latest Blocks") }}
              </h2>
            </div>
            <div
              class="js-scrollbar card-body overflow-hidden"
              style="height: 400px"
              v-loading="txnLoading"
            >
              <div v-for="item in blocks" :key="item._id">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                      <div class="d-none d-sm-flex mr-2">
                        <span class="btn btn-icon btn-soft-secondary"
                          ><span class="btn-icon__inner text-dark"
                            >Bk</span
                          ></span
                        >
                      </div>
                      <div class="media-body">
                        <span class="d-inline-block d-sm-none">Block</span>
                        <a href="#" @click.prevent="showBlock(item.number)">{{
                          item.number
                        }}</a
                        ><span
                          class="
                            d-sm-block
                            small
                            text-secondary
                            ml-1 ml-sm-0
                            text-nowrap
                          "
                          data-countdown="4000"
                          >{{
                            formatDate(item.timestamp, "yyyy-MM-dd hh:mm:ss")
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div class="d-flex justify-content-between">
                      <div class="text-nowrap">
                        <span class="d-block mb-1 mb-sm-0"
                          >{{ getName("Home", "Validated By") }}
                          <a
                            class="text-truncate"
                            href="#"
                            v-on:click.prevent="showAddress(item.miner)"
                            >{{ item.memo ? item.memo : item.miner }}</a
                          ></span
                        ><a
                          href="#"
                          @click.prevent="showBlockTxs(item.number)"
                          data-toggle="tooltip"
                          title="Transactions in this Block"
                          >{{ item.txn }} txns
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="hr-space" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card h-100">
            <div class="card-header">
              <h2 class="card-header-title">
                {{ getName("Home", "Latest Transactions") }}
              </h2>
            </div>
            <div
              class="js-scrollbar card-body overflow-hidden"
              style="height: 400px"
              v-loading="txnLoading"
            >
              <div v-for="item in txns" :key="item._id">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                      <div class="d-none d-sm-flex mr-2">
                        <span
                          class="btn btn-icon btn-soft-secondary rounded-circle"
                        >
                          <span class="btn-icon__inner text-dark">Tx</span>
                        </span>
                      </div>
                      <div class="media-body">
                        <span class="d-inline-block d-sm-none mr-1">TX# </span>
                        <!-- <a
                          class="
                            hash-tag hash-tag--xs
                            hash-tag-xs-down--md
                            text-truncate
                          "
                          href="#"
                          @click.prevent="showTransaction(item)"
                          >{{ item.hash }}
                        </a> -->
                        <ItemHash :hash="item.hash" />

                        <span class="d-none d-sm-block small text-secondary">
                          <div data-countdown="4000">{{ item.time }}</div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div class="d-sm-flex justify-content-between">
                      <div class="text-nowrap mr-4 mb-1 mb-sm-0">
                        <span
                          >{{ getName("Home", "From") }}
                          <!-- <a
                            class="hash-tag text-truncate"
                            @click.prevent="showAddress(item.from)"
                            href="#"
                            >{{ item.fromMemo ? item.fromMemo : item.from }}</a
                          >  -->
                          <ItemAddress
                            style="margin-start: 0.3rem"
                            currentAddress="currentAddr"
                            :address="item.from"
                            :info="item.fromInfo"
                          /> </span
                        ><span class="d-sm-block"
                          >{{ getName("Home", "To") }}
                          <!-- <a
                            href="#"
                            @click.prevent="showAddress(item.to)"
                            class="hash-tag text-truncate"
                            >{{ item.toMemo ? item.toMemo : item.to }}</a
                          > -->
                          <ItemAddress
                            style="margin-start: 0.3rem"
                            currentAddress="currentAddr"
                            :address="item.to"
                            :info="item.toInfo"
                          />
                        </span>
                      </div>
                      <div>
                        <span
                          class="
                            u-label
                            u-label--xs
                            u-label--badge-in
                            u-label--secondary
                            text-center text-nowrap
                          "
                          data-toggle="tooltip"
                          title=""
                          >{{ formatNumber(item.value, 4) }}
                          {{ item.symbol }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="hr-space" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="push" />
    </div>
  </main>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber, getDifficulty } from "../../public/assets/js/utils.js";
import { search } from "../../public/assets/js/search.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import { ElMessage } from "element-plus";
import route from "../router/index";
import { importJS } from "../../src/assets/js/utils.js";
import ItemAddress from "./components/itemAddress.vue";
import ItemHash from "./components/item_hash.vue";

export default {
  name: "Home",
  data() {
    return {
      blocks: [],
      txns: [],
      blockHeight: "",
      hashrate: "",
      price: "0.1",
      blocktime: "",
      difficulty: "",
      accountCounts: 0,
      txnLoading: true,
      interval_txns: null,
      interval_time: null,
      searchWords: "",
      searchLoading: false,
    };
  },
  components: {
    ItemAddress,
    ItemHash,
  },
  mounted() {
    PostNet.Post(PostNetUrl.latestInfo, (info) => {
      this.blockHeight = info.data.number;
      this.price = info.data.price;
      this.blocktime = info.data.blocktime + "s";
      this.hashrate = getDifficulty(info.data.hashrate) + "/s";
      this.difficulty = getDifficulty(info.data.difficulty);
      this.accountCounts = info.data.accounts;
    });
    PostNet.PostWith(
      PostNetUrl.indexUrl,
      (info) => {
        this.txnLoading = false;
        if (info.data) {
          this.blocks = info.data.blocks;
          this.caluetTime(info.data.txns);
          // this.txns = info.data.txns;
        }
      },
      (err) => {
        this.txnLoading = false;
      }
    );
    this.interval_txns = setInterval(() => {
      this.caluetTime(this.txns);
    }, 1000);
    this.interval_time = setInterval(() => {
      PostNet.Post(PostNetUrl.latestInfo, (info) => {
        this.blockHeight = info.data.number;
        this.price = info.data.price;
        this.blocktime = info.data.blocktime + "s";
        this.hashrate = getDifficulty(info.data.hashrate) + "/s";
        this.difficulty = getDifficulty(info.data.difficulty);
        this.accountCounts = info.data.accounts;
      });
      PostNet.Post(PostNetUrl.indexUrl, (info) => {
        this.blocks = info.data.blocks;
        this.caluetTime(info.data.txns);
      });
    }, 3000);
    // 这是滑动
    importJS("assets/js/header/home-js-scroolbar.js");
  },
  beforeUnmount() {
    if (this.interval_txns != null) {
      clearInterval(this.interval_txns);
    }
    if (this.interval_time != null) {
      clearInterval(this.interval_time);
    }
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showBlock(blockNum) {
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
      // location.href = "/block?b=" + blockNum;
    },
    showBlockTxs(blockNum) {
      // location.href = "/txs.html?b=" + blockNum;
      route.push({
        path: "/blocktxs",
        query: {
          b: blockNum,
        },
      });
    },
    showTransaction(item) {
      // if (item.transferType) {
      //   if ("token" === item.transferType) {
      //     // location.href =
      //     //   "/tx/tokentx.html?h=" + item.hash + "&s=" + item.symbol;
      //     route.push({
      //       path: "/txTokenDetail",
      //       query: {
      //         h: item.hash,
      //       },
      //     });
      //     return;
      //   }
      // }
      // location.href = "/tx/tx.html?h=" + item.hash;
      route.push({
        path: "/txDetail",
        query: {
          h: item.hash,
        },
      });
    },
    showAddress(addr) {
      //   location.href = "/address?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
    },
    showAccounts() {
      // location.href = "/accounts.html";
      route.push({
        path: "accounts",
      });
    },
    _search() {
      // console.log(this.searchWords);
      var item = this;
      if (this.searchWords.length > 0) {
        search(
          this.searchWords,
          route,
          PostNet,
          PostNetUrl,
          function (isLoading) {
            // console.log(item.searchLoading);
            // console.log(isLoading);
            item.searchLoading = isLoading;
          },
          function (str) {
            ElMessage.warning(str);
          }
        );
      }
    },
    caluetTime(data) {
      if (data.length === 0) {
        return;
      }
      var s = [];
      for (let i = 0; i < data.length; i++) {
        try {
          if (!isNaN(data[i]["timestamp"])) {
            const myDate = new Date();
            const now = myDate.getTime();
            const dis = now - data[i]["timestamp"] * 1000;
            var t = 0;
            if (dis < 0) {
              data[i]["time"] = 0 + " " + getName("Home", "sec");
            } else if (dis < 60000) {
              t = parseInt(dis / 1000);
              data[i]["time"] =
                t + " " + getName("Home", t > 1 ? "secs" : "sec");
            } else if (dis < 60 * 1000 * 60) {
              t = parseInt(dis / 1000 / 60);
              data[i]["time"] =
                t + " " + getName("Home", t > 1 ? "mins" : "min");
            } else if (dis < 24 * 60 * 1000 * 60) {
              t = parseInt(dis / 1000 / 60 / 60);
              data[i]["time"] =
                t + " " + getName("Home", t > 1 ? "hours" : "hour");
            } else if (dis < 365 * 24 * 60 * 1000 * 60) {
              t = parseInt(dis / 1000 / 60 / 60 / 24);
              data[i]["time"] =
                t + " " + getName("Home", t > 1 ? "days" : "day");
            } else {
              t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
              data[i]["time"] =
                t + " " + getName("Home", t > 1 ? "years" : "year");
            }
            data[i]["time"] = data[i]["time"] + " " + getName("Home", "ago");
          }
        } catch (e) {
          console.log(e);
        }
        s[i] = data[i];
      }
      this.txns = s;
    },
  },
  computed: {
    searchPlaceholder() {
      return getName("Home", "txtSearchInput");
    },
  },
};
</script>

<style lang="scss"></style>
