export function isAddress(address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    return true;
  } else {
    return false;
  }
}

export function isTransaction(tx) {
  if (!/^(0x)?[0-9a-f]{64}$/i.test(tx)) {
    // check if it has the basic requirements of an address
    return false;
  } else if (/^(0x)?[0-9a-f]{64}$/.test(tx) || /^(0x)?[0-9A-F]{64}$/.test(tx)) {
    // If it's all small caps or all all caps, return true
    return true;
  } else {
    // Otherwise check each case
    return false;
  }
}

export function search(s, route, PostNet, PostNetUrl, loadingChange, callback) {
  var search = s.toLowerCase().trimStart().trimEnd();
  if (isAddress(search)) {
    // location.href = "/address?a=" + search;
    //
    loadingChange(true);
    PostNet.PostWith(
      PostNetUrl.checkAddress,
      (info) => {
        if (info.data) {
          loadingChange(false);
          route.push({
            path: "/tokenDetail",
            query: {
              c: search,
            },
          });
        } else {
          route.push({
            path: "/address",
            query: {
              a: search,
            },
          });
        }
      },
      (err) => {
        loadingChange(false);
        callback("Search Error");
      },
      { address: search }
    );
  } else if (isTransaction(search)) {
    loadingChange(true);
    PostNet.PostWith(
      PostNetUrl.checkTransaction,
      (info) => {
        loadingChange(false);
        // if (info.data === "token") {
        //   // location.href = "/tx/tokentx.html?h=" + search;
        //   route.push({
        //     path: "/txTokenDetail",
        //     query: {
        //       h: search,
        //     },
        //   });
        // } else
        if (info.data === "transaction" || info.data === "token") {
          // location.href = "tx/tx.html?h=" + search;
          route.push({
            path: "/txDetail",
            query: {
              h: search,
            },
          });
        } else if (info.data === "block") {
          //   location.href = "/block/hash.html?h=" + search;
          route.push({
            path: "/block",
            query: {
              h: search,
            },
          });
        } else {
          callback("No result with: " + search);
        }
      },
      (err) => {
        loadingChange(false);
        callback("No result with: " + search);
      },
      { hash: search }
    );
  } else if (!isNaN(search)) {
    loadingChange(true);
    PostNet.PostWith(
      PostNetUrl.blockUrl,
      (info) => {
        loadingChange(false);
        if (info.data == null) {
          callback("No result with: " + search);
          return;
        }
        // location.href = "/block?b=" + search;
        route.push({
          path: "/block",
          query: {
            b: search,
          },
        });
      },
      (err) => {
        loadingChange(false);
        callback("No result with: " + search);
      },
      { number: parseInt(search) }
    );
  } else {
    callback("No result with: " + search);
  }
}
